import React from 'react';
import ReactDOM from 'react-dom/client';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Header from './Header.js'
import Bio from './Bio.js'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <Header />
    <Bio />
  </>
);
